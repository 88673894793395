.dunning-background {
    background-position: top;
    background-size: cover;
    bottom: 0;
    -webkit-filter: blur(4px);
    filter: blur(4px);
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    width: 100%;
}

.dunning-background::before {
  content: "";
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  pointer-events: none; 
}

.ui.grid.dunning-member-form-container {
    padding: 120px 40px;
}

.ui.segment.dunning-member-content-container {
    padding: 20px;
    padding: 40px 20px 30px 20px;
    margin: 0 auto;
    max-width: 760px;
}

.dunning-member-content {
    max-width: 600px;
    margin: 0 auto;
}

.dunning-brand-logo-container {
    text-align: center;
    margin-bottom: 10px;
}

.segment-form-container > form > input{
    background-color: red;
}

.segment-form-container > form > div.form-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.success-container {
    display: flex;
    flex-direction: column;
    align-items: center;    
}

.success-container-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 0;
}

.success-container-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.success-container-content > a {
    color: #ccc;
    text-decoration: underline;
}

.success-container-content > a.main {
    color: #000;
}

.check-container {
    width: 60px;    
}

.ui.icon.message > i.icon:not(.close) {
    font-size: 24px;
}

.error-view-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.error-view-container > i {
    font-size: 32px;
    color: #9e3a38;
}

.error-view-contact-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.error-view-contact-container > div {
    display: flex;
    align-items: center;
    gap: 4px;
}

footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

@media only screen and (max-width: 767px) {     
    footer > button.ui.secondary.button {
        width: 100%;
    }

    .ui.grid.dunning-member-form-container {
        padding: 16px;
        margin-left: auto;
        margin-right: auto;
    }

    .ui.segment.dunning-member-content-container {
        padding: 20px;
    }

    .segment-form-container > form > div.form-header {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 8px;
    }

    iframe[name='%card%#chargify-first-name'], iframe[name='%card%#chargify-last-name']  {
        height: 75px !important;
    }   

    iframe[name='%card%#chargify-card'], iframe[name='%card%#chargify-month'] {
        max-height: 150px !important;
    } 

    .ui.form:not(.unstackable) .fields:not(.unstackable)>.field {
        margin-bottom: 0;
    }
}

@media only screen and (max-width:370px) {
    .ui.grid.dunning-member-form-container {
        padding: 16px 4px;
    }
}

@media only screen and (min-width:768px) and (max-width: 991px) {

     iframe[name='%card%#chargify-card'] {
        display: flex; 
        width: 300px !important;
    } 

    iframe[name='%card%#chargify-first-name'] {
        width: 300px !important;
    }

    iframe[name='%card%#chargify-month'] {
        width: 230px !important;
    }
}