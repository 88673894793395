.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
}

.path.circle {
-webkit-animation: dash .9s ease-in-out;
animation: dash .9s ease-in-out;
}

.path.check {
stroke-dashoffset: -100;
-webkit-animation: dash-check .9s .35s ease-in-out forwards;
animation: dash-check .9s .35s ease-in-out forwards;
}

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}

@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}