.team-member-toggle-container {
  display: flex; 
  flex-direction: column;
  align-items: center;
}

.team-member-toggle-text {
  line-height: 1.5em;
  font-size: 16px;
  font-weight: 400;
  color: #5E5E5E
}